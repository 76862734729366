<script setup>
const showCountrySelector = ref(false);
import { ref, computed } from 'vue';
import { Modal } from '@hobbii/hobbii-ui';
import IconChevronDown from '../Icons/IconChevronDown.vue';
import FlagAT from '@/images/flag-AT.svg';
import FlagAU from '@/images/flag-AU.svg';
import FlagBE from '@/images/flag-BE.svg';
import FlagCA from '@/images/flag-CA.svg';
import FlagCH from '@/images/flag-CH.svg';
import FlagDE from '@/images/flag-DE.svg';
import FlagDK from '@/images/flag-DK.svg';
import FlagEE from '@/images/flag-EE.svg';
import FlagES from '@/images/flag-ES.svg';
import FlagFI from '@/images/flag-FI.svg';
import FlagFO from '@/images/flag-FO.svg';
import FlagFR from '@/images/flag-FR.svg';
import FlagGB from '@/images/flag-GB.svg';
import FlagGL from '@/images/flag-GL.svg';
import FlagGR from '@/images/flag-GR.svg';
import FlagHK from '@/images/flag-HK.svg';
import FlagHU from '@/images/flag-HU.svg';
import FlagIE from '@/images/flag-IE.svg';
import FlagIM from '@/images/flag-IM.svg';
import FlagIS from '@/images/flag-IS.svg';
import FlagIT from '@/images/flag-IT.svg';
import FlagJP from '@/images/flag-JP.svg';
import FlagLT from '@/images/flag-LT.svg';
import FlagLU from '@/images/flag-LU.svg';
import FlagLV from '@/images/flag-LV.svg';
import FlagNL from '@/images/flag-NL.svg';
import FlagNO from '@/images/flag-NO.svg';
import FlagNZ from '@/images/flag-NZ.svg';
import FlagPL from '@/images/flag-PL.svg';
import FlagPT from '@/images/flag-PT.svg';
import FlagSE from '@/images/flag-SE.svg';
import FlagSI from '@/images/flag-SI.svg';
import FlagSK from '@/images/flag-SK.svg';
import FlagUS from '@/images/flag-US.svg';
import { useTranslations } from '@/shared/composables/useTranslations.ts';

const { t } = useTranslations();

const props = defineProps({
  countryConfigs: {
    type: Array,
    required: true
  },
  cartCurrency: {
    type: String,
    required: true
  }
});
const currentCountryCode = window.Shopify.country.toLowerCase();

const getCountrySwitcherUrl = (countryConfig) =>
  countryConfig.shopifyConfig?.storeEndpoint ||
  `${countryConfig.legacyStore?.url}common/country_switcher/redirect?country=${countryConfig.countryCode}`;

const countries = computed(() => {
  const mapped = props.countryConfigs.map((countryConfig) => ({
    code: countryConfig.countryCode,
    name: t(
      `footer.country_switcher.${countryConfig.countryCode.toLowerCase()}`
    ),
    url: getCountrySwitcherUrl(countryConfig)
  }));

  mapped.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return mapped;
});

const getFlagComponent = (countryCode) => {
  switch (countryCode.toUpperCase()) {
    case 'AT':
      return FlagAT;
    case 'AU':
      return FlagAU;
    case 'BE':
      return FlagBE;
    case 'CA':
      return FlagCA;
    case 'CH':
      return FlagCH;
    case 'DE':
      return FlagDE;
    case 'DK':
      return FlagDK;
    case 'EE':
      return FlagEE;
    case 'ES':
      return FlagES;
    case 'FI':
      return FlagFI;
    case 'FO':
      return FlagFO;
    case 'FR':
      return FlagFR;
    case 'GB':
      return FlagGB;
    case 'GL':
      return FlagGL;
    case 'GR':
      return FlagGR;
    case 'HK':
      return FlagHK;
    case 'HU':
      return FlagHU;
    case 'IE':
      return FlagIE;
    case 'IM':
      return FlagIM;
    case 'IS':
      return FlagIS;
    case 'IT':
      return FlagIT;
    case 'JP':
      return FlagJP;
    case 'LT':
      return FlagLT;
    case 'LU':
      return FlagLU;
    case 'LV':
      return FlagLV;
    case 'NL':
      return FlagNL;
    case 'NO':
      return FlagNO;
    case 'NZ':
      return FlagNZ;
    case 'PL':
      return FlagPL;
    case 'PT':
      return FlagPT;
    case 'SE':
      return FlagSE;
    case 'SI':
      return FlagSI;
    case 'SK':
      return FlagSK;
    case 'US':
      return FlagUS;
  }
  return null;
};
</script>

<template>
  <button
    class="flex items-center gap-1.5 rounded-md border border-transparent py-2 text-white hover:text-primary-200"
    @click="showCountrySelector = !showCountrySelector"
  >
    <img
      :src="getFlagComponent(currentCountryCode)"
      class="h-auto w-5"
      width="20"
      height="20"
      loading="lazy"
      :alt="currentCountryCode"
    />
    <span class="font-bold underline"
      >{{ t(`footer.country_switcher.${currentCountryCode}`) }} ({{
        cartCurrency
      }})</span
    >
    <IconChevronDown class="-ml-1 text-xl" />
  </button>

  <Modal v-model:active="showCountrySelector" size="lg">
    <template #header>
      <div class="flex w-full justify-center text-xl font-bold">
        {{ t('footer.country_switcher.change_country') }}
      </div>
    </template>
    <template #body>
      <div class="columns-2 space-y-1 md:columns-3">
        <template v-for="country in countries" :key="country.countryCode">
          <a
            :href="country.url"
            class="flex items-center gap-2 rounded-md p-2 text-sm transition-colors duration-100 hover:bg-gray-100 hover:text-secondary hover:no-underline"
          >
            <img
              :src="getFlagComponent(country.code)"
              class="h-auto w-5 shrink-0"
              width="20"
              height="20"
              loading="lazy"
              :alt="country.name"
            />
            <span>{{ country.name }}</span>
          </a>
        </template>
      </div>
    </template>
  </Modal>
</template>
